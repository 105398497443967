/* 구독 > 목록 - 새로운 콘텐츠 */
.lytScrNewContents {width:100%; margin:0; margin-bottom: 125px;}
.lytUylListTopbtn + .lytScrNewContents {margin-top:-34px;}
.lytScrNewContents .contentsArea {width:100%; max-width:1385px; margin:0 auto;}
.lytScrNewContents .contentsTitGroup {margin-bottom:30px; position:relative;}
.lytScrNewContents .contentsTitGroup .title {height:40px;}
.lytScrNewContents .contentsTitGroup .title > h3 {height: 40px; font-size: 24px; font-weight: bold; line-height: 1.67; letter-spacing: -0.5px; color: #000000;}
.lytScrNewContents .contentsTitGroup .pagenation {width:80px; height:40px; position:absolute; right:0; top:0;}
.lytScrNewContents .contentsTitGroup .pagenation:after {content:""; display:block; clear:both;}
.lytScrNewContents .contentsTitGroup .pagenation .btnPrev {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-left.svg) center no-repeat; float:left;}
.lytScrNewContents .contentsTitGroup .pagenation .btnNext {width:40px; height:40px; font-size:0; background:url(/images/icon/btn-l-arrow-right.svg) center no-repeat; float:left;}
.lytScrNewContents .contentsContGroup {}
.lytScrNewContents .contentsContGroup .swiper-container {}
.lytScrNewContents .swiper-container {}
.lytScrNewContents .swiper-container .swiper-wrapper {}
.lytScrNewContents .swiper-container .swiper-slide {min-width:212px;}
.lytScrNewContents .swiper-container .swiper-slide:last-child {margin-right:0;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .lytScrNewContents {margin:50px 0;}
    .lytScrNewContents .contentsArea {width:100%; max-width:none; margin:0 auto;}
    .lytScrNewContents .contentsTitGroup {margin-bottom:16px; padding:0 20px;}
    .lytScrNewContents .contentsTitGroup .title {height:32px;}
    .lytScrNewContents .contentsTitGroup .title > h3 {height: 32px; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px;}
    .lytScrNewContents .contentsTitGroup .pagenation {display:none;}
    .lytScrNewContents .swiper-container {padding-left:20px;}
    .lytScrNewContents .swiper-container .swiper-slide {width:212px !important;}
}

.cptThumBoard {width:100%; }
.cptThumBoard:after {content:""; display:block; clear:both;}
.cptThumBoard .thumLink {width:100%; height:100%; display:block; cursor:pointer; text-align:left;}
.cptThumBoard .thumImgGroup {width:100%; background:#f2f2f2; position:relative;}
.cptThumBoard .thumImgGroup .imgBox {width:100%; font-size: 0; position: relative; padding-top: 81.25%; background-size: cover;}
.cptThumBoard .thumImgGroup .imgBox img {width:100%; height:100%; max-width:100%; max-height:100%; object-fit:cover; position: absolute; left: 0; top: 0;}
.cptThumBoard .thumImgGroup .imgIcon {position:absolute; left:12px; top:12px;}
.cptThumBoard .thumImgGroup .imgIcon img {width:36px;}
.cptThumBoard .thumImgGroup .imgInfo {width:100%; height:60px; background:#f2f2f2;}
.cptThumBoard .thumImgGroup .imgInfo:after {content:""; display:block; clear:both;}
.cptThumBoard .thumImgGroup .imgInfo .infoBox {padding-right:15px; padding-top:10px; float:right;}
.cptThumBoard .thumImgGroup .imgInfo .infoBox:after {content:""; display:block; clear:both;}
.cptThumBoard .thumImgGroup .imgInfo .infoBox li {margin-left:10px; position:relative; float:left;}
.cptThumBoard .thumImgGroup .imgInfo .infoLike {}
.cptThumBoard .thumImgGroup .imgInfo .infoLike .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-fav.svg) center no-repeat;}
.cptThumBoard .thumImgGroup .imgInfo .infoLike.on .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-fav-added.svg) center no-repeat;}
.cptThumBoard .thumImgGroup .imgInfo .infoLike .cmt {padding:1px 23px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #000000; white-space:nowrap; position:absolute; left:-46px; top:-50px; box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15); background-color: #ffffff;}
.cptThumBoard .thumImgGroup .imgInfo .infoLike .cmt:after {content:""; display:block; width:0; height:0; margin-left:-3px; border-width:5px 3px;
    border-color:#ffffff transparent transparent transparent; border-style:solid; position:absolute; left:50%; bottom:-10px;}
.cptThumBoard .thumImgGroup .imgInfo .infoPlay {}
.cptThumBoard .thumImgGroup .imgInfo .infoPlay .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-playlist.svg) center no-repeat;}
.cptThumBoard .thumImgGroup .imgInfo .infoPlay.on .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-playlist-added.svg) center no-repeat;}
.cptThumBoard .thumImgGroup .imgInfo .infoPlay .cmt {padding:1px 23px; font-size: 12px; line-height: 2.5; letter-spacing: -0.25px; color: #000000; white-space:nowrap; position:absolute; right:-10px; top:-42px; z-index:5; box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15); background-color: #ffffff;}
.cptThumBoard .thumImgGroup .imgInfo .infoPlay .cmt:after {content:""; display:block; width:0; height:0; margin-left:-3px; border-width:5px 3px;
    border-color:#ffffff transparent transparent transparent; border-style:solid; position:absolute; right:16px; bottom:-10px;}
.cptThumBoard .thumImgGroup .imgInfo .infoHour {font-family: Rubik; font-size: 18px; font-weight: bold; font-stretch: normal; letter-spacing: -0.37px; color: #000000;}

.cptThumBoard .thumContGroup {width:100%; position:relative;}
.cptThumBoard .thumTitle {padding-top:16px;}
.cptThumBoard .thumTitle h5 {width: 100%; font-size: 18px; font-weight: bold; line-height: 1.78; letter-spacing: -0.38px; color: #000000; display: block; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-align:left;}
.cptThumBoard .thumInfo {font-size:0; position:absolute; left:0; top:-32px;}
.cptThumBoard .thumInfo .infoBlack {height:20px; padding:6px 10px; font-size: 12px; font-weight: 500; line-height: 1.67; letter-spacing: -0.16px; color: #ffffff; background:#000000; display:inline-block;}
.cptThumBoard .thumInfo .infoGrey {height:20px; padding:6px 10px; font-size: 12px; font-weight: 500; line-height: 1.67; letter-spacing: -0.16px; color: #ffffff; background:#7f7f7f; display:inline-block;}

.cptThumBoard .thumGroup {height: 24px; margin-top:8px;}
.cptThumBoard .thumGroup .name {font-size: 14px; font-weight: normal; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c;}
.cptThumBoard .thumGroup .name:after {content:" ∙ ";}
.cptThumBoard .thumGroup .name:last-child:after {content:"";}

.cptThumBoard .thumEtc {margin-top:16px; font-size:0;}
.cptThumBoard .thumEtc .free {height: 20px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; display:inline-block; position:relative; float:left;}
.cptThumBoard .thumEtc .like {height: 20px; padding-left:20px; margin-left:16px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; display:inline-block; position:relative; float:right;}
.cptThumBoard .thumEtc .like:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-16-like.svg) center no-repeat; display:block; position:absolute; left:0; top:2px;}
.cptThumBoard .thumEtc .liked {height: 20px; padding-left:20px; margin-left:16px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; display:inline-block; position:relative; float:right;}
.cptThumBoard .thumEtc .liked:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-16-like-blue.svg) center no-repeat; display:block; position:absolute; left:0; top:2px;}
.cptThumBoard .thumEtc .view {height: 20px; padding-left:20px; margin-left:16px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; display:inline-block; position:relative; float:right;}
.cptThumBoard .thumEtc .view:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-16-view.svg) center no-repeat; display:block; position:absolute; left:0; top:2px;}

.cptThumBoard .thumEtc .cost {padding:0 11px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; position:relative;}
.cptThumBoard .thumEtc .cost:first-child {padding-left:0;}
.cptThumBoard .thumEtc .cost:first-child:after {display:none;}
.cptThumBoard .thumEtc .cost:last-child {padding-right:0;}
.cptThumBoard .thumEtc .cost:after {content:""; width:1px; height:14px; background:#e5e5e5; display:block; position:absolute; left:0; top:4px;}

@media screen and (min-width: 100px) and (max-width: 1279px) {}
@media screen and (min-width: 100px) and (max-width: 1023px) {
    .cptThumBoard .thumImgGroup {width:100%; float:left;}
    .cptThumBoard .thumImgGroup .imgIcon {display:none;}
    .cptThumBoard .thumImgGroup .imgInfo {height:40px;}
    .cptThumBoard .thumImgGroup .imgInfo .infoBox {padding-right:15px; padding-top:8px;}
    .cptThumBoard .thumImgGroup .imgInfo .infoBox li {margin-top:-8px; margin-right:-7px;}
    .cptThumBoard .thumImgGroup .imgInfo .infoLike .cmt {padding:12px 28px; font-weight: 500; line-height: 1.67; letter-spacing: -0.25px; color: #ffffff; right:0; top:-22px; background-color: #000000;}
    .cptThumBoard .thumImgGroup .imgInfo .infoLike .cmt:after {margin-right:-10px; border-width:8px 5px; border-color:#000000 transparent transparent transparent; bottom:-16px;}
    .cptThumBoard .thumImgGroup .imgInfo .infoPlay .cmt {padding:1px 23px; font-weight: 500; line-height: 1.67; letter-spacing: -0.25px; color: #ffffff; right:0; top:-22px; background-color: #000000;}
    .cptThumBoard .thumImgGroup .imgInfo .infoPlay .cmt:after {margin-right:-10px; border-width:8px 5px; border-color:#000000 transparent transparent transparent; bottom:-16px;}
    .cptThumBoard .thumImgGroup .imgInfo .infoHour {font-family: Rubik; font-size: 18px; font-weight: bold; font-stretch: normal; letter-spacing: -0.37px; color: #000000;}

    .cptThumBoard .thumContGroup {width:100%; padding-top: 5px; float:left;}
    .cptThumBoard .thumTitle {padding-top:0; margin-top:4px;}
    .cptThumBoard .thumTitle:first-child {margin-top:0;}
    .cptThumBoard .thumTitle h5 {height:48px; font-size: 14px; line-height: 1.71; letter-spacing: -0.29px;}
    .cptThumBoard .thumInfo {margin-top:4px; font-size:0; position:static;}
    .cptThumBoard .thumInfo:first-child {margin-top:0;}
    .cptThumBoard .thumInfo .infoBlack {height:auto; padding:0 11px 0 0; font-size: 12px; font-weight: normal; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; background:none;}
    .cptThumBoard .thumInfo .infoGrey {height:auto; padding:0 11px; font-size: 12px; font-weight: normal; line-height: 1.67; letter-spacing: -0.25px; color: #7c7c7c; background:none;}
    .cptThumBoard .thumInfo .infoGrey:after {content:""; width:0px; height:14px; background:#e5e5e5; display:block; position:absolute; left:0; top:4px;}

    .cptThumBoard .thumGroup {height: 20px; margin-top:2px;}
    .cptThumBoard .thumGroup .name {font-size: 12px;}
    .cptThumBoard .thumGroup .name:after {content:" > ";}
    .cptThumBoard .thumGroup {margin-top:4px; font-size:0;}
    .cptThumBoard .thumGroup .view {margin-left:8px;}

    /* .cptThumBoard .thumEtc {margin-top:40px;} */

    /* 스와이퍼용 */
    .swiper-container .cptThumBoard .thumImgGroup {width: 212px; max-width: 212px; float: none;}
    .swiper-container .cptThumBoard .thumContGroup {width: 100%; padding-left: 0; margin-top:8px; float: none;}
    .swiper-container .cptThumBoard .thumInfo .infoGrey:after {display:none;}
    .swiper-container .cptThumBoard .thumEtc {margin-top:4px;}

    .cptThumBoard .thumEtc .free {width:55%;}
}

.hide {
    font-size: 0 !important;
    position: fixed;
    left: -5000px;
    top: 0;
}